@import '~foundation-sites/scss/util/util';

.periods_container {
  display: flex;
  gap: 6px;
  height: 88px;
}

.box {
  width: 300px;
  height: 100%;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  position: relative;
  border: 1px solid #6d7f92;
  color: #fff;

  .premium_period {
    color: #fb1e5b;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .price {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;

    .currency {
      font-weight: 700;
      font-size: 16px;
      line-height: normal;
    }

    .period_text {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      text-transform: lowercase;
    }
  }

  .oeccbbAdvantage {
    display: flex;

    span {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    & > span:nth-child(1) {
      color: #06d9b1;
    }
  }

  .freeMonth {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    margin-top: 10px;
  }

  .savings_percent {
    color: #06d9b1;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    text-transform: lowercase;
  }

  .price_off {
    position: relative;

    // float: right;
    color: #de4848;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-transform: lowercase;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      border-radius: 1px;
      background: #de4848;
      top: 50%;
      right: 0;
    }
  }

  .infosOffer {
    display: flex;
    flex-direction: column;

    .savings_percent {
      color: #06d9b1;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      text-transform: lowercase;
    }

    .price_off {
      position: relative;
      float: left;
      color: #de4848;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-transform: lowercase;

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        border-radius: 1px;
        background: #de4848;
        top: 50%;
        right: 0;
      }
    }
  }
}

@include breakpoint(small only) {
  .periods_container {
    height: 124px;
    display: flex;
    flex-direction: column;

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }
  }
}
