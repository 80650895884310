@import '~foundation-sites/scss/util/util';

.features {
  margin: 4rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .separator {
    position: absolute;
    width: 1px;
    height: 100px;
    background: #6d7f92;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 180px;
}

.label {
  margin-top: 0.3rem;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  padding: 5px;
}

@include breakpoint(small only) {
  .features {
    flex-direction: column;

    .separator {
      display: none;
    }
  }
}
