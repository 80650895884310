@import '~foundation-sites/scss/util/util';

.banner {
  background: linear-gradient(
      180deg,
      rgba(181, 101, 123, 0) 0.09%,
      rgba(76, 9, 28, 0.7) 73.92%,
      #901135 99.91%
    ),
    url('/img/premium-offer-home-banner.png') !important;

  background-size: contain;
  background-repeat: no-repeat;

  .titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 100px;

    .container {
      max-width: 693px;
    }

    h1 {
      color: #fb1e5b;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 36px */
      letter-spacing: -0.3px;
      text-transform: uppercase;
    }

    h2 {
      overflow: hidden;
      color: #fff;
      text-align: center;
      text-overflow: ellipsis;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.8px;
    }

    h6 {
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      color: #ffffff;
      margin: auto;
      text-align: center;
    }

    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .actionButton a {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }

      .actionButtonMobile a {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        font-size: 12px;
      }

      .premiumAllCourses {
        color: #d8dde2;
        text-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }

  .discover {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #d8dde2;
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
  }

  .svg {
    path {
      fill: #fb1e5b;
    }
  }
}

@include breakpoint(medium down) {
  .banner {
    .titles {
      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: 20px;
      }

      h6 {
        font-size: 12px;
      }

      .action {
        .premiumAllCourses {
          font-size: 12px;
        }
      }
    }

    .discover {
      font-size: 12px;
    }
  }
}
