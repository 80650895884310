@import 'util/util.scss';

.search {
  background-color: #29394d;
  overflow-y: auto;

  section {
    padding: 3rem 0;
    position: relative;
    z-index: 1;
    .section-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.5rem;
    }
    .section-body {
      padding: 2.5rem 0;
    }
  }

  .not_found {
    background: transparent;
    margin: 6rem auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    padding: 0 20px;
    text-align: center;

    color: #18a0fb;

    p {
      font-size: 16px;
      line-height: 18px;
      color: #fff;
    }
  }
}

.nbResult {
  background: #2a3a4c;
  margin-left: 1rem;
  padding: 0.1rem 1rem;
  border-radius: 30px;
  color: white;
}

.list {
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
    grid-gap: 20px;
    justify-content: space-between;

    .event_card {
      padding: 10px 0px;
    }
  }
}

// Media queries --------------------------------
@include breakpoint(medium) {
  .search {
    height: unset;
  }

  .list {
    .event_card {
      padding: unset;
      margin-bottom: 4rem;
    }
  }
}

@include breakpoint(large) {
  .search {
    .not_found {
      margin-top: 8rem;
    }
  }
}
